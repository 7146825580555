(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/common/normalize.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/common/normalize.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  getUrlForBetOffer
} = svs.components.oddset.kambiOffering;
const {
  BetOfferTypeId,
  OutcomeStatus,
  SocketMessagePayloadKey
} = svs.components.oddset.kambiOffering.common.constants;
const {
  Odds: formatOdds
} = svs.utils.format;
const getOutcomeLabel = (outcome, betOfferType) => {
  switch (betOfferType) {
    case BetOfferTypeId.Handicap:
      {
        const line = outcome.line / 1000;
        return "".concat(outcome.label, " ").concat(outcome.line > 0 ? '+' : '').concat(Math.round(line * 10) / 10);
      }
    case BetOfferTypeId.OverUnder:
      {
        const line = outcome.line / 1000;
        return "".concat(outcome.label, " ").concat(Math.round(line * 10) / 10);
      }
    case BetOfferTypeId.YesNo:
    case BetOfferTypeId.Match:
    default:
      return outcome.label;
  }
};
const normalizeOutcomes = (outcomesArr, betOfferType) => outcomesArr.map(outcome => {
  const isSuspended = outcome.status === OutcomeStatus.Suspended;
  return _objectSpread(_objectSpread({}, outcome), {}, {
    id: outcome.id,
    label: getOutcomeLabel(outcome, betOfferType),
    odds: !outcome.odds ? null : formatOdds(outcome.odds / 1000),
    isSuspended
  });
});

const normalizeBetOffer = (betOffer, fullApiResponse) => {
  const betOfferType = betOffer.betOfferType.id;
  const betOfferId = betOffer.id;
  return {
    id: betOfferId,
    type: betOfferType,
    outcomes: normalizeOutcomes(betOffer.outcomes, betOfferType, betOfferId),
    deepLinkPath: getUrlForBetOffer(betOffer, fullApiResponse),
    isSuspended: Boolean(betOffer.suspended),
    key: betOfferId,
    englishLabel: betOffer.criterion.englishLabel,
    label: betOffer.criterion.label
  };
};
const normalizeBetOffers = (betOffersArr, fullApiResponse) => betOffersArr.map(betOffer => normalizeBetOffer(betOffer, fullApiResponse));
const normalizeSocketMessages = messageArr => messageArr.map(message => ({
  timestamp: message.t,
  type: message.mt,
  payload: message[SocketMessagePayloadKey[message.mt]]
}));
setGlobal('svs.components.oddset.kambiOffering.common.normalize', {
  normalizeBetOffers,
  normalizeBetOffer,
  normalizeSocketMessages
});

 })(window);