(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/common/constants.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/common/constants.js');
"use strict";


const BetOfferTypeId = {
  Match: 2,
  OverUnder: 6,
  YesNo: 18,
  Handicap: 1,
  Result: 3
};
const OutcomeStatus = {
  Open: 'OPEN',
  Closed: 'CLOSED',
  Suspended: 'SUSPENDED',
  Settled: 'SETTLED'
};

const SocketMessageType = {
  EventAdded: 4,
  MatchClockUpdated: 15,
  LiveEventOpened: 5,
  BetOfferAdded: 6,
  BetOfferOddsAdded: 22,
  BetOfferStatusUpdated: 8,
  EventRemoved: 18,
  MatchClockRemoved: 12,
  EventScoreUpdated: 16,
  EventStatsUpdated: 17,
  EventDescriptionUpdated: 20,
  BetOfferRemoved: 7,
  BetOfferOddsUpdated: 11,
  BetOfferOddsRemoved: 23,
  OutcomesStatusUpdated: 32,
  EventStateUpdated: 34,
  EventStartTimeUpdated: 35
};
const SocketMessagePayloadKey = {
  [SocketMessageType.EventAdded]: 'ea',
  [SocketMessageType.MatchClockUpdated]: 'mcu',
  [SocketMessageType.LiveEventOpened]: 'leo',
  [SocketMessageType.BetOfferAdded]: 'boa',
  [SocketMessageType.BetOfferOddsAdded]: 'booa',
  [SocketMessageType.BetOfferStatusUpdated]: 'bosu',
  [SocketMessageType.EventRemoved]: 'er',
  [SocketMessageType.MatchClockRemoved]: 'mcr',
  [SocketMessageType.EventScoreUpdated]: 'score',
  [SocketMessageType.EventStatsUpdated]: 'stats',
  [SocketMessageType.EventDescriptionUpdated]: 'desc',
  [SocketMessageType.BetOfferRemoved]: 'bor',
  [SocketMessageType.BetOfferOddsUpdated]: 'boou',
  [SocketMessageType.BetOfferOddsRemoved]: 'boor',
  [SocketMessageType.OutcomesStatusUpdated]: 'osu',
  [SocketMessageType.EventStateUpdated]: 'esu',
  [SocketMessageType.EventStartTimeUpdated]: 'estu'
};
setGlobal('svs.components.oddset.kambiOffering.common.constants', {
  BetOfferTypeId,
  OutcomeStatus,
  SocketMessageType,
  SocketMessagePayloadKey
});

 })(window);