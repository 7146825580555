(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/offering.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/offering.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let trinidad;
let logger;
let kambiOfferingApiUrl;
let ProxyAgent;

if (svs.isServer) {
  var _trinidad$config;
  trinidad = require('trinidad-core').core;
  ProxyAgent = require('undici').ProxyAgent;
  logger = trinidad.logger('components:oddset:kambi-offering');
  kambiOfferingApiUrl = (_trinidad$config = trinidad.config) === null || _trinidad$config === void 0 || (_trinidad$config = _trinidad$config.options) === null || _trinidad$config === void 0 || (_trinidad$config = _trinidad$config.oddset) === null || _trinidad$config === void 0 ? void 0 : _trinidad$config.kambiOfferingApiUrl;
} else {
  var _svs$oddset;
  trinidad = svs.core;
  logger = new svs.core.log.Logger('components:oddset:kambi-offering');
  kambiOfferingApiUrl = (_svs$oddset = svs.oddset) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.kambiOfferingApiUrl) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.data) === null || _svs$oddset === void 0 ? void 0 : _svs$oddset.kambiOfferingApiUrl;
}
function isNumber(value) {
  return Number.isInteger(value);
}

const getBetOffers = async function (eventId, betOfferTypes) {
  let optionalParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!eventId || !isNumber(eventId) && !(typeof eventId === 'string')) {
    throw new Error("eventId: ".concat(eventId, " is not a correct eventId or is missing"));
  }
  if (!betOfferTypes || !Array.isArray(betOfferTypes) || !betOfferTypes.every(isNumber)) {
    throw new Error("betOfferTypes: ".concat(betOfferTypes, " is not a correct betOfferTypes or is missing"));
  }
  try {
    let offeringRes;
    const params = _objectSpread(_objectSpread({}, optionalParams), {}, {
      lang: 'sv_SE',
      type: betOfferTypes.join(',')
    });
    const requestUrl = "".concat(kambiOfferingApiUrl, "/betoffer/event/").concat(eventId, "?").concat(Object.keys(params).map(key => "".concat(key, "=").concat(params[key])).join('&'));
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json'
      },
      credentials: 'omit'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      const dispatcher = new ProxyAgent(process.env.HTTP_PROXY);
      offeringRes = await fetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher
      }));
    } else {
      offeringRes = await fetch(requestUrl, options);
    }
    const offeringResJson = await offeringRes.json();
    return offeringResJson;
  } catch (error) {
    logger.info('Error while fetching kambi bet offers', JSON.stringify(error));
    throw error;
  }
};

const getBetOffer = async function (eventId, betOfferType) {
  let optionalParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return await getBetOffers(eventId, [betOfferType], optionalParams);
};

const getLiveData = async function (eventId) {
  let optionalParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!eventId || !isNumber(eventId) && !(typeof eventId === 'string')) {
    throw new Error("eventId: ".concat(eventId, " is not a correct eventId or is missing"));
  }
  try {
    let liveDataRes;
    const params = _objectSpread(_objectSpread({}, optionalParams), {}, {
      lang: 'sv_SE'
    });
    const requestUrl = "".concat(kambiOfferingApiUrl, "/event/").concat(eventId, "/livedata?").concat(Object.keys(params).map(key => "".concat(key, "=").concat(params[key])).join('&'));
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json'
      },
      credentials: 'omit'
    };
    if (svs.isServer && process.env.HTTP_PROXY) {
      const dispatcher = new ProxyAgent(process.env.HTTP_PROXY);
      liveDataRes = await fetch(requestUrl, _objectSpread(_objectSpread({}, options), {}, {
        dispatcher
      }));
    } else {
      liveDataRes = await fetch(requestUrl, options);
    }
    const liveDataResJson = await liveDataRes.json();
    return liveDataResJson;
  } catch (error) {
    logger.info('Error while fetching kambi live data', JSON.stringify(error));
    throw error;
  }
};
const exported = {
  getBetOffer,
  getBetOffers,
  getLiveData
};
if (svs.isServer) {
  module.exports = exported;
} else {
  setGlobal('svs.components.oddset.kambiOffering', exported);
}

 })(window);