(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/store/store.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/store/store.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  SocketMessageType,
  OutcomeStatus
} = svs.components.oddset.kambiOffering.common.constants;
const {
  Odds: formatOdds
} = svs.utils.format;
const ActionType = _objectSpread({
  SetBetOffers: 'SetBetOffers'
}, SocketMessageType);
const initState = {};
const reducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetBetOffers:
      {
        return action.payload;
      }
    case ActionType.OutcomesStatusUpdated:
      {
        const {
          payload
        } = action;
        const betOfferId = payload.outcomes[0].betOfferId;
        const betOfferToUpdate = state[betOfferId];
        if (!betOfferToUpdate) {
          return state;
        }
        const updatedOutcomes = state[betOfferId].outcomes.map(outcome => {
          const updOutcome = payload.outcomes.find(updOutcome => outcome.id === updOutcome.id);
          if (updOutcome) {
            const isSuspended = updOutcome.status !== OutcomeStatus.Open;
            return _objectSpread(_objectSpread({}, outcome), {}, {
              isSuspended
            });
          }
          return outcome;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          [betOfferId]: _objectSpread(_objectSpread({}, betOfferToUpdate), {}, {
            outcomes: updatedOutcomes
          })
        });
      }
    case ActionType.BetOfferRemoved:
      {
        const betOffersCopy = _objectSpread({}, state);
        delete betOffersCopy[action.payload.betOfferId];
        return betOffersCopy;
      }
    case ActionType.BetOfferOddsUpdated:
      {
        const {
          payload
        } = action;
        const betOfferId = payload.outcomes[0].betOfferId;
        const betOfferToUpdate = state[betOfferId];
        if (!betOfferToUpdate) {
          return state;
        }
        const updatedOutcomes = state[betOfferId].outcomes.map(outcome => {
          const updOutcome = payload.outcomes.find(updOutcome => outcome.id === updOutcome.id);
          if (updOutcome) {
            return _objectSpread(_objectSpread({}, outcome), {}, {
              odds: !updOutcome.odds ? null : formatOdds(updOutcome.odds / 1000)
            });
          }
          return outcome;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          [betOfferId]: _objectSpread(_objectSpread({}, betOfferToUpdate), {}, {
            outcomes: updatedOutcomes
          })
        });
      }
    default:
      return state;
  }
};
setGlobal('svs.components.oddset.kambiOffering.store', {
  initState,
  reducer,
  ActionType
});

 })(window);