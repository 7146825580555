(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/deep-link.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/deep-link.js');
"use strict";



const diacriticalMarksRegexp = /[\u0300-\u036f\u1ab0-\u1aff\u1dc0-\u1dff\u20d0-\u20ff\ufe20-\ufe2f\u0483-\u0486\u05c7\u0610-\u061a\u0656-\u065f\u0670\u06d6-\u06ed\u0711\u0730-\u073f\u0743-\u074a\u0f18-\u0f19\u0f35\u0f37\u0f72-\u0f73\u0f7a-\u0f81\u0f84\u0e00-\u0eff\ufc5e-\ufc62]/g;

const removeAccents = str => str.normalize('NFD').replace(diacriticalMarksRegexp, '');

const sanitizeName = input => input.toLowerCase().replaceAll(' ', '-').replaceAll('æ', 'ae').replaceAll('ø', 'o').replaceAll('å', 'aa').replaceAll('ª', 'a').replaceAll('/', '-').replaceAll('|', '');
const trinidad = svs.isServer ? require('trinidad-core').core : svs.core;


const getUrlForBetOffer = (betOffer, offeringRes) => {
  if (!betOffer || !betOffer.eventId) {
    throw new Error('No valid betOffer');
  }
  let url = "".concat(trinidad.urlMapping.get('oddsetHome'), "/sv/sports");
  const event = offeringRes.events.find(event => event.id === betOffer.eventId);
  event.path.forEach(path => {
    url += "/".concat(removeAccents(sanitizeName(path.name)));
  });
  url += "/".concat(event.id);
  return url;
};
const exported = {
  getUrlForBetOffer
};
if (svs.isServer) {
  module.exports = exported;
} else {
  setGlobal('svs.components.oddset.kambiOffering', exported);
}

 })(window);