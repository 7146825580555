(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-offering/assets/javascripts/hooks/use-bet-offer-sockets.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-offering/assets/javascripts/hooks/use-bet-offer-sockets.js');
"use strict";

var _svs$oddset, _svs$oddset2;

const {
  useEffect,
  useState
} = React;
const kambiPushApiUrl = (_svs$oddset = svs.oddset) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.kambiOfferingApiUrl) === null || _svs$oddset === void 0 || (_svs$oddset = _svs$oddset.data) === null || _svs$oddset === void 0 ? void 0 : _svs$oddset.kambiPushApiUrl;
const kambiOfferingSocketChannelPrefix = (_svs$oddset2 = svs.oddset) === null || _svs$oddset2 === void 0 || (_svs$oddset2 = _svs$oddset2.kambiOfferingApiUrl) === null || _svs$oddset2 === void 0 || (_svs$oddset2 = _svs$oddset2.data) === null || _svs$oddset2 === void 0 ? void 0 : _svs$oddset2.kambiOfferingSocketChannelPrefix;
const logger = new svs.core.log.Logger('components:oddset:kambi-offering:hooks:useBetOfferSockets');
const LANGUAGE = 'sv_SE';
const MAXIMUM_ATTEMPTS = 5;
const useBetOfferSockets = (eventId, onMessageCallback, shouldConnectSockets) => {
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!shouldConnectSockets) {
      logger.info("Kambi event ".concat(eventId, " opted out socket updates."));
      return;
    }
    if (!eventId) {
      const error = "Missing eventId. Not opening socket connection";
      logger.error(error);
      setError(error);
      return;
    }
    if (!kambiPushApiUrl || !kambiOfferingSocketChannelPrefix) {
      const error = "Missing values from zookie config. kambiPushApiUrl = ".concat(kambiPushApiUrl, " | kambiOfferingSocketChannelPrefix = ").concat(kambiOfferingSocketChannelPrefix);
      logger.error(error);
      setError(error);
      return;
    }
    const socket = io(kambiPushApiUrl, {
      transports: ['websocket'],
      upgrade: false,
      autoConnect: false,
      path: '/socket.io'
    });
    socket.on('connect', () => {
      logger.debug('Socket connected');
    });
    socket.on('disconnect', reason => {
      logger.info("Socket disconnected: ".concat(reason));
    });
    socket.on('reconnect', attemptNumber => {
      logger.info("Socket reconnected after ".concat(attemptNumber, " attempts."));
    });
    socket.on('error', error => {
      const errorMsg = "Socket error: ".concat(error);
      logger.error(errorMsg);
      setError(errorMsg);
    });
    socket.on('reconnect_attempt', attemptNumber => {
      logger.info("Socket reconnect attempt ".concat(attemptNumber));
      if (attemptNumber >= MAXIMUM_ATTEMPTS) {
        logger.error("Closing socket after ".concat(attemptNumber, " reconnection attempts."));
        socket.close();
      }
    });
    socket.on('message', incomingMessage => onMessageCallback(JSON.parse(incomingMessage)));
    socket.open();
    const languageSpecificChannel = "".concat(kambiOfferingSocketChannelPrefix, ".").concat(LANGUAGE, ".ev.").concat(eventId, ".json");
    const nonLocalizedChannel = "".concat(kambiOfferingSocketChannelPrefix, ".ev.").concat(eventId, ".json");
    socket.emit('subscribe', {
      topic: languageSpecificChannel
    });
    socket.emit('subscribe', {
      topic: nonLocalizedChannel
    });
    return () => {
      socket.emit('unsubscribe', {
        topic: languageSpecificChannel
      });
      socket.emit('unsubscribe', {
        topic: nonLocalizedChannel
      });
    };
  }, [eventId, shouldConnectSockets, onMessageCallback]);
  return {
    error
  };
};
setGlobal('svs.components.oddset.kambiOffering.hooks.useBetOfferSockets', useBetOfferSockets);

 })(window);